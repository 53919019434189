<template>
  <div>
    <spinner-component :loaded="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-container>
      <v-row>
        <v-col cols="4">
          <v-text-field
              v-model="method.name"
              label="Nazwa metody dostawy"
          >

          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
              :items="couriers"
              label="Obsługiwane usługi kurierskie"
              v-model="method.couriers"
          >

          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
              @click="addNewShipmentZone()"
          >
            Dodaj nową strefe
          </v-btn>
          <v-list>
            <v-list-item
                v-for="(zone, i) in method.shipmentZones"
                :key="i"
            >
              <v-text-field
                  label="Nazwa"
                  v-model="zone.name"
              >
              </v-text-field>

              <v-text-field
                  label="Koszt"
                  v-model="zone.cost"
              >
              </v-text-field>

              <v-combobox
                  v-model="zone.supportedCountries"
                  chips
                  clearable
                  label="Strefy dostawy"
                  multiple
                  prepend-icon="mdi-filter-variant"
                  solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click="select"
                      @click:close="removeSupportedCountry(zone, item)"
                  >
                    <strong>{{ item }}</strong>&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
              <v-list-item-action>
                <v-btn
                    @click="removeShipmentZone(zone)"
                >
                  Usuń
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
              @click="updateMethod()"
          >
            Zapisz
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import SpinnerComponent from "@/components/SpinnerComponent";
import {shipmentApiClient} from "@/api/shipment.api.client";

export default {
  name: 'NewShipmentMethodsView',
  inheritAttrs: false,
  components: {ErrorDialogComponent, SpinnerComponent},

  props: {
    methodId: String
  },

  data() {
    return {
      method: {},
      couriers: [],

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  created() {
    this.getMethod()
    this.getCouriers();
  },

  methods: {
    getCouriers() {
      this.loading = true;
      shipmentApiClient.getCouriers((response) => {
        this.couriers = response.data;
      }, () => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = "Błąd ładowanie dostępnych usług kurierskich!";
      }, () => this.loading = false)
    },

    removeSupportedCountry(zone, item) {
      zone.supportedCountries.splice(zone.supportedCountries.indexOf(item), 1)
    },

    removeShipmentZone(item) {
      this.method.shipmentZones.splice(this.method.shipmentZones.indexOf(item), 1)
    },

    getMethod() {
      this.loading = true;
      shipmentApiClient.getShipmentMethod(this.methodId, (response) => {
        this.method = response.data;
      }, () => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = "Błąd ładowanie metofdy dostawy!";
      }, () => this.loading = false)
    },

    addNewShipmentZone() {
      this.method.shipmentZones.push({
        name: '',
        cost: '',
        supportedCountries: []
      });
    },

    updateMethod() {
      this.loading = true;
      const data = {
        name: this.method.name,
        couriers: this.method.couriers,
        shipmentZones: this.method.shipmentZones
      };
      shipmentApiClient.updateShipmentMethod(this.methodId, data, () => {
        this.$router.push({name: 'shipment-methods-all'})
      }, () => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = "Błąd podczas edycji metody dostawy!";
      }, () => this.loading = false)
    }
  }
}
</script>
