<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-card>
      <v-card-title>
        Dane zamawiającego
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                  label="Imię"
                  v-model="client.name"
                  :disabled="!edit"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  :disabled="!edit"
                  label="Nazwisko"
                  v-model="client.surname"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  :disabled="!edit"
                  label="Ulica"
                  v-model="client.street"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  :disabled="!edit"
                  label="Numer domu"
                  v-model="client.houseNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="2" v-if="client.aptNumber">
              <v-text-field
                  :disabled="!edit"
                  label="Numer lokalu"
                  v-model="client.aptNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field
                  :disabled="!edit"
                  label="Kod pocztowy"
                  v-model="client.zipCode"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  :disabled="!edit"
                  label="Miasto"
                  v-model="client.city"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Kraj"
                  v-model="client.country"
                  :disabled="!edit"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Adres email"
                  v-model="client.email"
                  :disabled="!edit"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  :disabled="!edit"
                  label="Numer telefonu"
                  v-model="client.phone"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="edit" @click="edit = true">Edytuj</v-btn>
        <v-btn :disabled="!edit" @click="save()">Zapisz</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {computed} from "vue";
import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {ordersApiClient} from "@/api/orders.api.client";

export default {
  name: 'OrderClientDetails',
  inheritAttrs: false,
  components: {SpinnerComponent, ErrorDialogComponent},

  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },

    orderId: String
  },
  computed: {
    client() {
      return this.modelValue ? this.modelValue : {}
    }
  },

  emits: ['update:modelValue'], // The component emits an event
  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  data() {
    return {
      edit: false,

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false,
        callback: undefined
      }
    }
  },

  created() {
  },

  methods: {
    save() {
      this.loading = true;
      ordersApiClient.updateClientData(this.orderId, this.client, () => {
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
        this.errorDialog.callback = () => this.$router.go(0);
      }, () => {
        this.loading = false;
        this.edit = false;
      })
    }
  }
};
</script>
