<template>
  <v-container>
    <v-row>
      <v-col>
        <router-link :to="{ name: 'new-product' }">

          <v-btn block
                 color="primary"
                 elevation="2"
                 large
          >
            Dodaj nowy produkt
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
            class="elevation-1"
            :headers="headers"
            :items="parsedProducts"
            :search="search"
        >
          <template v-slot:top>
            <v-text-field
                v-model="search"
                label="Szukaj"
                class="mx-4"
            ></v-text-field>
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-select
                          v-model="categories.values"
                          :items="categories.items"
                          attach
                          chips
                          label="Kategoria"
                          multiple
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                          v-model="types.values"
                          :items="types.items"
                          attach
                          chips
                          label="Typ"
                          multiple
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                          v-model="statuses.values"
                          :items="statuses.items"
                          attach
                          chips
                          label="Status"
                          multiple
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-btn
                      @click="applyFilter()"
                  >
                    Filtruj
                  </v-btn>
                </v-container>
              </v-card-text>
            </v-card>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  Akcje
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <router-link style="text-decoration: none; color: inherit;"
                                 :to="{ name: 'product', params: { productId: item.id } }">
                      <v-btn>Edytuj</v-btn>
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                        @click="removeProduct(item)"
                    >
                      Usuń
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {productApiClient} from "@/api/product.api.client";

export default {
  name: 'ProductsBaseView',
  inheritAttrs: false,
  components: {},

  props: {
    status: String
  },

  data() {
    return {
      search: '',
      parsedProducts: [],
      headers: [
        {text: "Typ", value: 'type'},
        {text: "Kategoria", value: 'categories'},
        {text: "Pozycja na liście", value: 'position'},
        {text: "Nazwa", value: 'title'},
        {text: "Sprzedaż", value: 'sold'},
        {text: "Status", value: 'status'},
        {text: "Akcje", sortable: false, value: 'actions'}
      ],
      types: {
        items: ['BH', 'JWB'],
        values: [],
      },
      statuses: {
        items: ['DRAFT', 'PUBLISHED', 'REMOVED'],
        values: [],
      },
      categories: {
        items: ['BOY', 'GIRL', 'BOTH', 'SPECIAL'],
        values: [],
      },
      filter: {
        types: [],
        statuses: [],
        categories: []
      }
    }
  },

  created() {
    this.handleStatus();
  },

  methods: {
    handleStatus() {
      switch (this.status) {
        case 'published':
          this.statuses.values.push('PUBLISHED');
          this.filter.statuses.push('PUBLISHED');
          this.getProducts(this.filter);
          break;
        case 'draft':
          this.statuses.values.push('DRAFT');
          this.filter.statuses.push('DRAFT');
          this.getProducts(this.filter);
          break;
        default:
          this.getProducts(this.filter);
      }
    },

    removeProduct(product) {
      productApiClient.removeProduct(product, () => {
        this.getProducts(this.filter);
      }, (error) => {
        console.error(error);
      });
    },

    getProducts(filter) {
      productApiClient.getInternalListing(filter, (response) => {
        response.data.forEach(product => {
          this.parsedProducts.push({
            id: product.id,
            type: product.type,
            categories: product.categories,
            position: product.position,
            title: product.title,
            sold: 0,
            status: product.status
          })
        }, (error) => {
          console.error(error);
        });
      })
    },

    applyFilter() {
      this.parsedProducts = [];
      this.filter.statuses = this.statuses.values;
      this.filter.types = this.types.values;
      this.filter.categories = this.categories.values;
      this.getProducts(this.filter);
    }
  }
};
</script>
