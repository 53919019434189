<template>
  <v-overlay
      :opacity="1"
      :value="loading"
  >
    <v-progress-circular indeterminate size="64">
    </v-progress-circular>
  </v-overlay>
</template>

<script>

export default {
  name: 'SpinnerComponent',
  props: {
    loading: Boolean,
  },

  components: {},

  data() {
    return {}
  },
}
</script>
