import {default as axios} from "axios";
import {VOUCHERS_URL} from "@/config/api.config";
import Vue from "vue";
import {ACCESS_TOKEN} from "@/api/auth.utils";

export class VouchersApiClient {
    addVoucher(voucherToAdd, successCallback, errorCallback, finallyCallback = () => {}) {
        axios.post(VOUCHERS_URL, voucherToAdd, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    editVoucherGroup(groupId, updatedVoucher, successCallback, errorCallback, finallyCallback) {
        axios.put(VOUCHERS_URL + `/${groupId}`, updatedVoucher, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getVouchers(successCallback, errorCallback, finallyCallback = () => {}) {
        axios.get(VOUCHERS_URL, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getVoucherGroupsByGroupName(groupType, successCallback, errorCallback, finallyCallback = () => {}) {
        axios.get(VOUCHERS_URL + `/group/${groupType}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getVoucher(voucherId, successCallback, errorCallback, finallyCallback) {
        axios.get(VOUCHERS_URL + `/${voucherId}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    deleteVoucherGroup(groupId, successCallback, errorCallback, finallyCallback) {
        axios.delete(VOUCHERS_URL + `/${groupId}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }
}

export const vouchersApiClient = new VouchersApiClient();