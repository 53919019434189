<template>
  <v-container
      class="px-0"
      fluid
  >
    <v-row>
      <v-col>
        <v-radio-group v-model="radioGroup">
          <v-radio
              label="Wszystkie produkty"
              value="all"
              @change="allSelected"
          ></v-radio>

          <v-radio
              label="Wybrane kategorie"
              value="categories"
              @change="categoriesSelected"
          ></v-radio>
          <v-select
              v-if="radioGroup === 'categories'"
              v-model="local.conditions.categoryCondition.categories"
              :items="allowedCategories"
              label="Wybierz kategorie"
              multiple
              attach
              chips
          ></v-select>

          <v-radio
              label="Wybrane produkty"
              value="products"
              @change="productsSelected"
          ></v-radio>
          <v-select
              v-if="radioGroup === 'products'"
              :items="products"
              v-model="local.conditions.productsCondition.discountedProducts"
              label="Wybierz produkty"
              multiple
              attach
              chips
              item-text="title"
              item-value="id"
          ></v-select>

          <v-radio
              label="Wybrane typy produktów"
              value="productTypes"
              @change="productTypesSelected"
          ></v-radio>
          <v-select
              v-if="radioGroup === 'productTypes'"
              :items="allowedTypes"
              v-model="local.conditions.typeCondition.productTypes"
              label="Wybierz typy produktów"
              multiple
              attach
              chips
          ></v-select>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {productApiClient} from "@/api/product.api.client";
import {computed} from "vue";

export default {
  name: 'GenericConditions',
  props: {
    modelValue: Object,
  },

  computed: {
    local() {
      return this.modelValue ? this.modelValue : {}
    }
  },

  components: {},

  emits: ['update:modelValue'], // The component emits an event

  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  created() {
    this.getProducts();
    if (!!this.modelValue.conditions.allCondition === true) {
      this.radioGroup = 'all';
    }
    if (!!this.modelValue.conditions.categoryCondition === true) {
      this.radioGroup = 'categories';
    }
    if (!!this.modelValue.conditions.typeCondition === true) {
      this.radioGroup = 'productTypes';
    }
    if (!!this.modelValue.conditions.productsCondition === true) {
      this.radioGroup = 'products';
    }
  },

  methods: {
    getProducts() {
      productApiClient.getProducts((response) => {
        this.products = response.data;
      }, () => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = "Error adding new product!";
      })
    },

    categoriesSelected() {
      this.local.conditions = {
        categoryCondition: {
          categories: []
        }
      }
    },
    productTypesSelected() {
      this.local.conditions = {
        typeCondition: {
          productTypes: []
        }
      }
    },
    productsSelected() {
      this.local.conditions = {
        productsCondition: {
          discountedProducts:[]
        }
      }
    },
    allSelected() {
      this.local.conditions = {
        allCondition: {}
      }
    }
  },

  data() {
    return {
      radioGroup: 'all',

      // SOURCES
      allowedTypes: ['BH', 'JWB'],
      allowedCategories: ['BOY', 'GIRL', 'BOTH', 'SPECIAL'],
      products: []
    }
  },
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
