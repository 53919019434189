import {default as axios} from "axios";
import {PROPERTIES_URL} from "@/config/api.config";
import Vue from "vue";
import {ACCESS_TOKEN} from "@/api/auth.utils";

export class PropertiesApiClient {
    getProperties(successCallback, errorCallback, finallyCallback) {
        axios.get(PROPERTIES_URL, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getProperty(id, successCallback, errorCallback, finallyCallback) {
        axios.get(PROPERTIES_URL + `/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    updateProperties(data, successCallback, errorCallback, finallyCallback) {
        axios.put(PROPERTIES_URL, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    addProperties(data, successCallback, errorCallback, finallyCallback) {
        axios.post(PROPERTIES_URL, data, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    deleteProperties(id, successCallback, errorCallback, finallyCallback) {
        axios.delete(PROPERTIES_URL + `/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }
}

export const propertiesApiClient = new PropertiesApiClient();