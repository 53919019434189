<template>
  <v-container>
    <v-row>
      <v-col>
        <v-data-table
            :headers="headers"
            :items="parsedZones"
            class="elevation-1"
        >
          <template v-slot:[`item.countries`]="{ item }">
            <v-list>
              <v-list-item
                  v-for="country in item.countries"
                  :key="country"
              >
                <v-list-item-title>
                  {{ country }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'ShipmentZonesComponent',
  inheritAttrs: false,
  components: {},

  props: {
    methodId: String,
    zones: []
  },

  data() {
    return {
      headers: [
        {text: "Name", value: 'name'},
        {text: "Koszt", value: 'cost'},
        {text: "Kraje", value: 'countries'}
      ],
      parsedZones: []
    }
  },

  created() {
    this.parseZones()
  },

  methods: {
    parseZones() {
      this.parsedZones = this.zones.map(zone => {
        return {
          name: zone.name,
          cost: zone.cost,
          countries: zone.supportedCountries
        }
      })
    }
  }
}
</script>
