<template>
  <v-card>
    <v-card-text>
      <v-text-field
          disabled
          label="Typ zamówienia:"
          v-model="order.orderType.orderType"
      ></v-text-field>
      <v-text-field
          disabled
          label="Status zamówienia:"
          v-model="order.status.statusName"
      ></v-text-field>
      <v-text-field
          disabled
          label="Status płatności:"
          v-model="order.paymentInfo.operationStatus"
      ></v-text-field>

      <v-text-field
          disabled
          label="Zamówiono:"
          v-model="order.metadata.orderedAt"
      ></v-text-field>
      <v-text-field
          disabled
          label="Ostatnia aktualizacja:"
          v-model="order.metadata.lastModified"
      ></v-text-field>
      <v-text-field
          disabled
          label="Zmodyfikowano przez:"
          v-model="order.metadata.lastModifiedBy"
      ></v-text-field>
      <v-text-field
          disabled
          label="Kod rabatowy:"
          v-model="order.notes.code"
      ></v-text-field>
      <v-text-field
          disabled
          label="Realizacja:"
          v-model="isExpress"
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>

import {computed} from "vue";

export default {
  name: 'OrderMetadataDetails',
  inheritAttrs: false,
  components: {},
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    order() {
      return this.modelValue ? this.modelValue : {}
    },
  },

  emits: ['update:modelValue'], // The component emits an event
  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  data() {
    return {
      isExpress: 'Normalna'
    }
  },

  created() {
    this.isExpress = this.evaluateExpress()
  },

  methods: {
    evaluateExpress() {
      if (this.order.notes.express) {
        return "EXPRESS";
      }
      return "Normalna";
    }
  }
};
</script>
