<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-container class="mx-4">
      <v-row>
        <v-col cols="6">
          <v-file-input
              show-size
              v-model="csvFile"
              accept=".csv"
              prepend-icon=""
              append-icon="mdi-file"
              placeholder="Importuj z pliku CSV"
              @change="importCSV()"
          >
          </v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
              v-model="singleAddress"
              label="Adres email"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn
              @click="addAddress"
          >
            Dodaj ręcznie
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-btn
              @click="deleteSelectedAddresses"
          >
            Usuń zaznaczone
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
              v-model="selectedAddresses"
              class="elevation-1"
              :headers="headers"
              :items="mailingList"
              :search="search"
              item-key="email"
              show-select
          >
            <template v-slot:top>
              <v-text-field
                  v-model="search"
                  label="Szukaj"
                  class="mx-4"
              ></v-text-field>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>


import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {mailsApiClient} from "@/api/mails.api.client";

export default {
  name: 'MailingListView',

  components: {SpinnerComponent, ErrorDialogComponent},

  data() {
    return {
      search: '',
      mailingList: [],

      selectedAddresses: [],
      singleAddress: '',

      headers: [
        {text: "Adres email", value: 'email'},
        {text: "Dodano", value: 'addedAt'},
        {text: "Akcje", sortable: false, value: 'actions'}
      ],

      csvFile: undefined,

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  created() {
    this.getAddresses()
  },

  methods: {
    addAddress() {
      if (this.singleAddress.trim().length > 0) {
        this.uploadNewAddresses([this.singleAddress]);
        this.singleAddress = '';
      }
    },

    deleteSelectedAddresses() {
      if (this.selectedAddresses.length > 0) {
        const addressesToRemove = this.selectedAddresses.map(address => address.email);
        this.deleteAddresses(addressesToRemove);
        this.selectedAddresses = [];
      }
    },

    importCSV() {
      const reader = new FileReader();
      reader.readAsText(this.csvFile);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          const data = reader.result.split('\r\n');
          this.uploadNewAddresses(data);
        }
      };
    },

    getAddresses() {
      this.loading = true;
      mailsApiClient.getAddresses((response) => {
            this.mailingList = response.data.map(email => {
              return {
                email: email.address,
                addedAt: email.addedAt
              }
            })
          }, (e) => {
            this.errorDialog.visible = true;
            this.errorDialog.errorMessage = "Błąd podczas pobierania list mailingowych!";
            console.error(e);
          }, () => this.loading = false
      )
    },

    uploadNewAddresses(addresses) {
      this.loading = true;
      mailsApiClient.uploadAddresses(addresses, (response) => {
            this.mailingList = response.data.map(email => {
              return {
                email: email.address,
                addedAt: email.addedAt
              }
            })
          }, () => {
            this.errorDialog.visible = true;
            this.errorDialog.errorMessage = "Błąd podczas dodawania do list mailingowych!";
          }, () => this.loading = false
      )
    },

    deleteAddresses(addresses) {
      this.loading = true;
      mailsApiClient.deleteEmailAddresses(addresses, () => {
            this.getAddresses();
          }, () => {
            this.errorDialog.visible = true;
            this.errorDialog.errorMessage = "Błąd podczas usuwania z list mailingowych!";
          }, () => this.loading = false
      )
    }
  }
};
</script>