<template>
  <v-app id="inspire">
    <v-snackbar
        v-model="snackbar"
    >
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card className="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Zaloguj się</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form
                    ref="form"
                >
                  <v-text-field
                      prepend-icon="mdi-arrow-down"
                      name="login"
                      label="Login"
                      type="text"
                      :rules="[rules.required, rules.email]"
                      v-model="credentials.login"
                  ></v-text-field>
                  <v-text-field
                      prepend-icon="mdi-arrow-down"
                      id="password"
                      name="password"
                      label="Password"
                      type="password"
                      :rules="[rules.required, rules.counter]"
                      v-model="credentials.password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="login()">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {computed} from "vue";
import {reactive} from "@vue/reactivity";
import {authUtils} from "@/api/auth.utils.js";

export default {
  name: 'LoginView',
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        logged: false
      }),
    },
  },

  computed: {
    local() {
      return this.modelValue ? this.modelValue : {}
    }
  },

  emits: ['update:modelValue'], // The component emits an event

  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  data() {
    return {
      credentials: reactive({
        login: '',
        password: ''
      }),

      // MISC
      errorMessage: "",
      snackbar: false,

      // VALIDATION RULES
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },

  methods: {
    login() {
      if (!this.areCredentialsValid()) {
        return;
      }
      const loginData = {
        loginId: this.credentials.login,
        password: this.credentials.password,
        applicationId: process.env.VUE_APP_APPLICATION_ID
      }
      authUtils.login(loginData, (userData) => {
            localStorage.setItem("user", JSON.stringify(userData));
            this.$router.push({name: 'profile'});
          },
          this.errorResponseHandler);
    },

    areCredentialsValid() {
      return this.$refs.form.validate();
    },

    errorResponseHandler(err) {
      if (err.response === undefined) {
        this.snackbar = true;
        this.errorMessage = err;
      } else if (err.response.status === 404) {
        this.snackbar = true;
        this.errorMessage = 'Invalid either login or password';
      } else if (err.response.status === 401) {
        this.snackbar = true;
        this.errorMessage = err.response.data.detail;
      } else {
        this.snackbar = true;
        this.errorMessage = err.response.data;
      }
    }
  }
};
</script>

<style scoped></style>
