<template>
  <div>
    <spinner-component :loaded="loading"></spinner-component>
    <v-container>
      <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>

      <v-stepper v-model="progress">
        <v-stepper-header>
          <v-stepper-step
              :complete="progress > 1"
              step="1"
          >
            Dodaj produkt
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
              step="2"
          >
            Dodaj zdjęcia
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <product-details :model-value="productData"></product-details>
            <v-btn
                color="primary"
                @click="saveProduct()"
            >
              Continue
            </v-btn>

            <v-btn text>
              <router-link to="/products/all">
                Cancel
              </router-link>
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <product-image-upload :product-id="productId"></product-image-upload>
            <router-link to="/products/all">
              Continue
            </router-link>
            <v-btn text>
              <router-link to="/products">
                Cancel
              </router-link>
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>

import ProductDetails from "@/components/products/ProductDetails";
import ProductImageUpload from "@/components/products/ProductImagesUpload";
import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {productApiClient} from "@/api/product.api.client";

export default {
  name: 'AddNewProductView',
  inheritAttrs: false,
  components: {ErrorDialogComponent, SpinnerComponent, ProductImageUpload, ProductDetails},

  data() {
    return {
      productId: String,
      progress: 1,
      productData: {
        prefixTitle: null,
        title: null,
        price: null,
        salePrice: null,
        description: null,
        details: null,
        locale: null,
        allowedTypes: ['BH', 'JWB'],
        type: null,
        allowedCategories: ['BOY', 'GIRL', 'BOTH', 'SPECIAL'],
        categories: [],
        kidCount: null,
        promoted: false,
        suggestedAge: 3,
        images: [],
        previewImages: [],
        position: -1,
        kind: ''
      },

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  created() {
  },

  methods: {
    saveProduct() {
      this.loading = true;
      const jsonData = {
        prefixTitle: this.productData.prefixTitle,
        title: this.productData.title,
        price: this.productData.price,
        salePrice: this.productData.salePrice,
        description: this.productData.description,
        details: this.productData.details,
        locale: this.productData.locale,
        type: this.productData.type,
        categories: this.productData.categories,
        kidCount: this.productData.kidCount,
        promoted: this.productData.promoted,
        suggestedAge: this.productData.suggestedAge,
        kind: this.productData.kind,
        position: this.productData.position
      };
      productApiClient.saveProduct(jsonData,
          (response) => {
            this.productId = response.data.id;
            this.progress = 2;
          },
          () => {
            this.errorDialog.visible = true;
            this.errorDialog.errorMessage = "Error adding new product!";
          }, () => this.loading = false)
    }
  },
};
</script>
