<template>
  <v-container>
    <v-row>
      <v-col cols="6" class="my-4 fluid">
        <v-text-field
            :value="userData.email"
            label="Email"
            disabled
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="my-4 fluid">
        <v-text-field
            :value="new Date(userData.lastLoginInstant)"
            label="Ostatnie logowanie"
            disabled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2" class="my-2 fluid">
        <v-checkbox
            v-model="userData.verified"
            label="Zweryfikowano"
            disabled
        ></v-checkbox>
      </v-col>
      <v-col cols="5" class="my-2 fluid">
        <v-text-field
            :value="userData.firstName"
            label="Imię"
            disabled
        ></v-text-field>
      </v-col>
      <v-col cols="5" class="my-2 fluid">
        <v-text-field
            :value="userData.lastName"
            label="Nazwisko"
            disabled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3" class="my-2 fluid">
        <v-checkbox
            v-model="userData.passwordChangeRequired"
            label="Wymagana zmiana hasła"
            disabled
        ></v-checkbox>
      </v-col>
      <v-col cols="3" class="my-2 fluid">
        <v-combobox
            v-model="userData.usernameStatus"
            :items="['ACTIVE', 'INACTIVE']"
            label="Status loginu"
            disabled
        ></v-combobox>
      </v-col>
      <v-col cols="6" class="my-2 fluid">
        <v-text-field
            :value="userData.id"
            label="Id użytkownika"
            disabled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-subheader>Moje role</v-subheader>
    <v-row>
      <v-col>
        <v-data-table
            :headers="headers"
            :items="registrations"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProfileView",
  inheritAttrs: false,
  props: {},

  created() {
    this.userData = JSON.parse(localStorage.getItem("user"));

    const applications = [
      {
        name: 'bookhero-api',
        id: process.env.VUE_APP_BOOKHERO_API_APPLICATION_ID
      },
      {
        name: 'bookhero-admin-console',
        id: process.env.VUE_APP_APPLICATION_ID
      }
    ]

    this.userData.registrations.forEach(registration => {
      const application = applications.find(app => app.id === registration.applicationId);
      this.registrations.push({
        applicationId: registration.applicationId,
        applicationName: application === undefined ? 'N/A' : application.name,
        roles: registration.roles,
        username: registration.username,
        usernameStatus: registration.usernameStatus,
        verified: registration.verified
      })
    })
  },

  data() {
    return {
      userData: {},
      headers: [
        {text: "Id aplikacji", value: 'applicationId'},
        {text: "Nazwa aplikacji", value: 'applicationName'},
        {text: "Role", value: 'roles'},
        {text: "Nazwa użytkownika", value: 'username'},
        {text: "Status użytkownika", value: 'usernameStatus'},
        {text: "Zweryfikowano", value: 'verified'}
      ],
      registrations: []
    }
  }
}
</script>

<style scoped>

</style>
