<template>
  <v-dialog
      v-model="errorDialog.visible"
  >
    <v-card>
      <v-card-title>Error</v-card-title>
      <v-card-text>
        {{ errorDialog.errorMessage }}
        <div>
          <v-divider></v-divider>
          {{ errorDialog.errorDetails }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="red"
            text
            @click="closeDialog()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {computed} from "vue";

export default {
  name: 'ErrorDialogComponent',
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    }
  },

  computed: {
    errorDialog() {
      return this.modelValue ? this.modelValue : false
    }
  },

  emits: ['update:modelValue'], // The component emits an event
  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  components: {},

  data() {
    return {
    }
  },

  methods: {
    closeDialog() {
      this.errorDialog.visible = false;
      if (this.errorDialog.callback !== undefined) {
        this.errorDialog.callback();
      }
    }
  }
}
</script>
