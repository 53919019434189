<template>
  <v-expansion-panels variant="popout" class="my-4">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template>
          <v-row no-gutters>
            <v-col cols="4" class="d-flex justify-start">
              Wiadomości i komentarze
            </v-col>
            <v-col
                cols="8"
                class="text-grey"
            >
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container>
          <v-row v-for="comment of comments" :key="comment">
            <v-col>
              <v-text-field
              >
                {{ comment }}
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

export default {
  name: 'OrderCommentsDetails',
  inheritAttrs: false,
  components: {},
  props: {
    comments: Array,
  },

  data() {
    return {
    }
  },

  created() {
  },

  methods: {

  }
};
</script>
