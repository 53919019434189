<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <v-container>
      <v-row>
        <v-col
            v-for="page in availablePages"
            :key="page.page"
            cols="4"
        >
          <v-card elevation="2">
            <v-card-title>
              Strona {{ page.page }}
            </v-card-title>
            <v-card-subtitle>
              Ostatnia aktualizacja: {{ page.lastEdited }} - {{ page.lastEditedBy }}
            </v-card-subtitle>
            <v-card-actions>
              <v-btn @click="editPage(page)">
                Edytuj
              </v-btn>
              <v-btn @click="deletePage(page)">
                Usuń
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <router-link style="text-decoration: none; color: inherit;"
                       :to="{ name: 'product', params: { product: this.productId } }">
            <v-btn block
                   color="primary"
                   elevation="2"
                   large>
              Wróć
            </v-btn>
          </router-link>
        </v-col>
        <v-col>
          <v-btn block
                 color="primary"
                 elevation="2"
                 large
                 @click="addNewPage()">
            Dodaj nową stronę
          </v-btn>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>

import SpinnerComponent from "@/components/SpinnerComponent";
import {metadataApiClient} from "@/api/metadata.api.client";
import {productApiClient} from "@/api/product.api.client";

export default {
  name: 'PagesView',
  components: {SpinnerComponent},

  props: {
    productId: String
  },

  data() {
    return {
      availablePages: [],
      kidCount: undefined,

      // MISC
      loading: false,
    }
  },

  created() {
    this.getPages();
    this.getKindCount();
  },

  methods: {
    editPage(page) {
      this.$router.push({
        name: 'designer',
        params: {productId: page.productId, page: String(page.page), updating: true, kidCount: this.kidCount}
      });
    },

    deletePage(page) {
      metadataApiClient.deletePage(this.productId, page, () => {
        this.getPages();
      }, (error) => {
        console.error(error);
      });
    },

    addNewPage() {
      this.$router.push({
        name: 'designer',
        params: {
          productId: this.productId,
          page: this.availablePages.length + 1,
          updating: false,
          kidCount: this.kidCount
        }
      })
    },

    getPages() {
      this.loading = true;
      metadataApiClient.getPages(this.productId, (response) => {
        this.availablePages = response.data;
      }, (error) => {
        console.error(error);
      }, () => this.loading = false);
    },

    getKindCount() {
      this.loading = true;
      productApiClient.getProductInternal(this.productId, (response) => {
        this.kidCount = response.data.kidCount;
      }, (error) => {
        console.error(error);
      }, () => this.loading = false);
    },
  }
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
