<template>
  <orders-base-view status="all"></orders-base-view>
</template>

<script>

import OrdersBaseView from "@/components/orders/OrdersBaseView";
export default {
  name: 'OrdersAllView',

  components: {OrdersBaseView},
};
</script>
