<template>
  <div>
    <div>
      <v-app-bar-title>Preview images</v-app-bar-title>
      <v-container>
        <v-row>
          <v-carousel>
            <v-carousel-item
                v-for="(previewImage, i) in this.previewImages"
                :key="i"
                eager
            >
              <v-card
                  class="mx-auto my-12"
                  width="600"
                  max-height="500"
              >
                <v-img
                    :src="backend + '/v1/products/' +productId + '/images/' +  previewImage.id"
                    contain max-width="600" max-height="300"/>
                <v-card-actions>
                  <v-btn
                      text
                      @click="removeProductImage(previewImage.id)"
                  >
                    Remove
                  </v-btn>
                  <v-btn
                      text
                      @click="saveProductImagePriority(previewImage.id, previewImage.priority)"
                  >
                    Save
                  </v-btn>
                  <v-text-field type="number" v-model="previewImage.priority" label="Priority"></v-text-field>
                </v-card-actions>
              </v-card>

            </v-carousel-item>
          </v-carousel>
        </v-row>
      </v-container>
      <v-file-input
          label="File input"
          @change="uploadProductImage($event, true)"
      ></v-file-input>
    </div>
    <div>
      <v-app-bar-title>Images</v-app-bar-title>
      <v-container>
        <v-row>
          <v-carousel>
            <v-carousel-item
                v-for="(image, i) in this.images"
                :key="i"
                eager
            >
              <v-card
                  class="mx-auto my-12"
                  width="600"
                  max-height="500"
              >
                <v-img
                    :src="backend + '/v1/products/' +productId + '/images/' +  image.id"
                    contain max-width="600" max-height="300"/>
                <v-card-actions>
                  <v-btn
                      text
                      @click="removeProductImage(image.id)"
                  >
                    Remove
                  </v-btn>
                  <v-btn
                      text
                      @click="saveProductImagePriority(image.id, image.priority)"
                  >
                    Save
                  </v-btn>
                  <v-text-field type="number" v-model="image.priority" label="Priority"></v-text-field>
                </v-card-actions>
              </v-card>

            </v-carousel-item>
          </v-carousel>
        </v-row>
      </v-container>
      <v-file-input
          label="File input"
          @change="uploadProductImage($event, false)"
      ></v-file-input>
    </div>
  </div>
</template>

<script>

import {productApiClient} from "@/api/product.api.client";

export default {
  name: 'ProductImageUpload',
  inheritAttrs: false,
  components: {},
  props: {
    productId: String,
  },

  data() {
    return {
      images: [],
      previewImages: [],
      backend: ''
    }
  },

  created() {
    this.backend = process.env.VUE_APP_BOOKHERO_API_PATH
    this.getProductPreviewImages();
    this.getProductImages();
  },

  methods: {
    getProductImages() {
      productApiClient.getProductImages(this.productId, (response) => {
        this.images = response.data;
      }, (error) => {
        console.error(error);
      }, () => {});
    },

    getProductPreviewImages() {
      productApiClient.getProductPreviewImages(this.productId, (response) => {
        this.previewImages = response.data;
      }, (error) => {
        console.error(error);
      }, () => {});
    },

    removeProductImage(imageId) {
      productApiClient.removeProductImage(this.productId, imageId, () => {
        this.getProductPreviewImages();
        this.getProductImages();
      }, (error) => {
        console.error('Failed to save data', error);
      }, () => {});
    },

    saveProductImagePriority(imageId, priority) {
      productApiClient.updateProductImagePriority(this.productId, imageId, {
        priority: priority
      }, () => {
        this.getProductPreviewImages();
        this.getProductImages();
      }, (error) => {
        console.error('Failed to save data', error);
      }, () => {});
    },

    uploadProductImage(file, preview) {
      let priority = 0;
      if (preview) {
        if (this.previewImages.length !== 0) {
          priority = Math.max(...this.previewImages.map(im => im.priority)) + 10;
        }
      } else {
        if (this.images.length !== 0) {
          priority = Math.max(...this.images.map(im => im.priority)) + 10;
        }
      }
      const formData = new FormData();
      formData.append('preview', preview);
      formData.append('priority', priority);
      formData.append('image', file);

      productApiClient.uploadProductImages(this.productId, formData, () => {
        this.getProductPreviewImages();
        this.getProductImages();
      }, (error) => {
        console.error('Failed to save data', error);
      }, () => {})
    },
  },
};
</script>
