<template>
  <div>
    <spinner-component :loaded="loading"></spinner-component>
    <v-container>
      <product-image-upload :product-id="productId"></product-image-upload>
      <product-details :model-value="product"></product-details>
      <v-row>
        <v-col v-if="product.type ==='BH'">
          <router-link :to="{ name: 'pages'}">
            <v-btn block
                   color="primary"
                   elevation="2"
                   large
            >
              Projektuj książeczkę
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
              v-model="product.status"
              :items="statuses"
              label="Status"
          >

          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <router-link :to="{ name: 'products' }">
            <v-btn block
                   color="primary"
                   elevation="2"
                   large
            >
              Wróć
            </v-btn>
          </router-link>
        </v-col>
        <v-col>
          <v-btn block
                 color="primary"
                 elevation="2"
                 large
                 @click="saveProduct()"
          >
            Zapisz
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import ProductDetails from "@/components/products/ProductDetails";
import ProductImageUpload from "@/components/products/ProductImagesUpload";
import SpinnerComponent from "@/components/SpinnerComponent";
import {productApiClient} from "@/api/product.api.client";

export default {
  name: 'UpdateProductsView',
  inheritAttrs: false,
  props: {
    productId: String,
  },

  components: {SpinnerComponent, ProductImageUpload, ProductDetails},

  data() {
    return {
      loading: false,
      product: Object,
      kidCount: Number,
      statuses:[
          'DRAFT', 'PUBLISHED', 'REMOVED'
      ]
    }
  },

  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          this.getProduct()
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
  },

  methods: {
    getProduct() {
      this.loading = true;
      productApiClient.getProductInternal(this.productId, (response) => {
        this.product = response.data;
        this.kidCount = response.data.kidCount;
      }, (error) => {
        console.error(error);
      }, () => this.loading = false);
    },

    saveProduct() {
      this.loading = true;
      const jsonData = {
        prefixTitle: this.product.prefixTitle,
        title: this.product.title,
        price: this.product.price,
        salePrice: this.product.salePrice,
        description: this.product.description,
        details: this.product.details,
        locale: this.product.locale,
        type: this.product.type,
        categories: this.product.categories,
        kidCount: this.product.kidCount,
        promoted: this.product.promoted,
        suggestedAge: this.product.suggestedAge,
        kind: this.product.kind,
        position: this.product.position,
        status: this.product.status
      };
      productApiClient.editProduct(this.productId, jsonData, () => {
        this.$router.push(`/products/all`);
      }, (error) => {
        console.error('Failed to save data', error);
      }, () => this.loading = false);
    }
  },
};
</script>
