import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router'
import 'vuetify/dist/vuetify.min.css';
import Vuetify from 'vuetify';
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

Vue.use(VueCookies, { expire: '7d'})
Vue.use(Vuetify, {
    theme: {
        primary: '#7957d5',
    },
});

new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')

export function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function subtractDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
}
