<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-card>
      <v-card-title>
        {{ entity.orderEntityVisibleId }} - {{ entity.product.title }}
      </v-card-title>
      <v-card-subtitle>
        Dedykacja: <br> <h4>{{ entity.dedication }}</h4>
        Płeć: <br> <h4>{{ entity.gender }}</h4>
        Próby generowania: <br> <h4>{{ entity.generationRetryCount }}</h4>
      </v-card-subtitle>
      <v-card-subtitle>
        Status: {{ entity.status.statusName }}
      </v-card-subtitle>
      <v-card-actions>
        <v-btn
            color="primary"
            dark
            v-if="entity.status.statusName === 'NEW' || entity.status.statusName === 'GENERATED' || entity.status.statusName === 'FAILED'"
            @click="regenerateEntity(entity.id)"
        >
          Generuj ponownie
        </v-btn>
        <v-btn
            color="primary"
            dark
            @click="verify(entity.id)"
            v-if="entity.status.statusName === 'GENERATED'"
        >
          Zweryfikowano
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-img
            v-if="entity.product.previewImages.length > 0"
            :src="backend + '/v1/products/' + entity.product.id + '/images/' + entity.product.previewImages[0].id"
            :alt="backend + '/v1/products/' + entity.product.id + '/images/' + entity.product.images[0].id"
            height="100px"
            width="100px"
        ></v-img>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import {computed} from "vue";
import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {entitiesApiClient} from "@/api/entities.api.client";

export default {
  name: 'BHOrderEntitiesDetails',
  inheritAttrs: false,
  components: {
    SpinnerComponent,
    ErrorDialogComponent
  },
  props: {
    automaticStatuses: [],
    order: {
      type: Object,
      default: () => ({}),
    },
    entityModel: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    entity() {
      return this.entityModel ? this.entityModel : {}
    },
    backend() {
      return process.env.VUE_APP_BOOKHERO_API_PATH
    }
  },

  emits: ['update:entityModel'], // The component emits an event
  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.entityModel,
      set: (value) => emit('update:entityModel', value),
    });

    return {theModel};
  },

  data() {
    return {
      formattedStatuses: {},

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  created() {
    this.formatStatuses();
  },

  methods: {
    formatStatuses() {
      this.automaticStatuses.forEach(status => {
        this.formattedStatuses[status.name] = status.id;
      })
    },

    verify() {
      this.loading = true;
      entitiesApiClient.verify(this.order.id, 'BH', this.entity.id, () => {
            this.entity.status.statusName = 'VERIFIED';
          },
          error => {
            this.errorDialog.visible = true;
            this.errorDialog.errorMessage = error.response.data.detail;
          }, () => this.loading = false)
    },

    regenerateEntity(entityId) {
      this.loading = true;
      entitiesApiClient.regenerateOrderEntity(this.order.id, entityId,
          () => {
            this.entity.status.statusName = 'GENERATING';
          },
          error => {
            this.errorDialog.visible = true;
            this.errorDialog.errorMessage = error.response.data.detail;
          }, () => this.loading = false)
    }
  }
};
</script>
