<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
            label="Maksymalna wartość [zł]"
            type="number"
            v-model="local.maximumValue"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-checkbox
            label="Darmowa dostawa"
            v-model="local.freeShipping"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
            label="Darmowa dedykacja"
            v-model="local.freeDedications"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-container v-if="type === 'PROGRESSIVE_BOOK_COUNT'">
      <v-row>
        <v-col>
          <v-btn
              text
              outlined
              @click="addThreshold()"
          >
            Dodaj nowy próg
          </v-btn>
        </v-col>
      </v-row>
      <v-row
          v-for="(t, i) in thresholds"
          :key="i"
      >
        <v-col>
          <v-text-field
              label="Ilość książek"
              type="number"
              v-model="local.thresholds[i].key"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
              label="Rabat procentowy"
              type="number"
              v-model="local.thresholds[i].value"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

  </v-container>
</template>

<script>
import {computed} from "vue";

export default {
  name: 'ProgressiveBookCountVoucherType',
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    type: String
  },

  computed: {
    local() {
      return this.modelValue ? this.modelValue : {}
    },
  },

  mounted() {
    if (this.modelValue.thresholds === undefined) {
      this.local.thresholds = [];
    }
    this.thresholds = this.local.thresholds;
  },

  emits: ['update:modelValue'], // The component emits an event

  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  components: {},

  data() {
    return {
      thresholds: []
    }
  },

  methods: {
    addThreshold() {
      this.local.thresholds.push({
        key: this.thresholds.length + 1,
        value: 0
      });
    },
  }
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
