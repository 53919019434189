import {default as axios} from "axios";
import {FONTS_URL} from "@/config/api.config";
import Vue from "vue";
import {ACCESS_TOKEN} from "@/api/auth.utils";

export class FontApiClient {
    addFont(formData, successCallback, errorCallback, finallyCallback) {
        return axios.post(FONTS_URL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getFont(fontId, successCallback, errorCallback, finallyCallback) {
        return axios.get(FONTS_URL + `/${fontId}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            },
            responseType: "arraybuffer"
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    preloadFont(fontName, successCallback, errorCallback, finallyCallback) {
        return axios.get(FONTS_URL + `/${fontName}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            },
            responseType: "arraybuffer"
        }).then(response => {
            return successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }

    getFonts(successCallback, errorCallback, finallyCallback) {
        return axios.get(FONTS_URL, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }
}

export const fontApiClient = new FontApiClient();