<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-card>
      <v-card-title>
        Zdjęcia do zamówienia
      </v-card-title>
      <v-data-table
          class="elevation-1"
          :headers="sourceFiles.headers"
          :items="sourceFiles.parsedFiles"
      >
        <template v-slot:top>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="userImage.name"
                    label="Imię"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-file-input
                    v-model="userImage.image"
                    label="Wgraj plik"
                    required
                ></v-file-input>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-btn
                    @click="uploadUserImageFile()"
                >
                  Dodaj
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-text-field
            v-model="item.name"
          >
          </v-text-field>

        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                Akcje
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-dialog
                      v-model="dialog"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="previewUserImageFile(item.link)"
                      >
                        Podgląd
                      </v-btn>
                    </template>
                    <v-card>
                      <v-toolbar
                          dark
                          color="primary"
                      >
                        <v-btn
                            icon
                            dark
                            @click="dialog = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            dark
                            @click="downloadUserImageFile(item.link, item.name)"
                        >
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-img :src="imagePreviewUrl"></v-img>
                    </v-card>
                  </v-dialog>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                      @click="downloadUserImageFile(item.link, item.name)"
                  >
                    Ściągnij
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                      @click="updateUserImageName(item.fileId, item.name)"
                  >
                    Zapisz imię
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                      @click="deleteUserImageFile(item.fileId)"
                  >
                    Usuń
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>

import {saveAs} from "file-saver";
import {default as axios} from "axios";
import Vue from "vue";
import {ACCESS_TOKEN} from "@/api/auth.utils";
import {ORDERS_URL} from "@/config/api.config";
import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {entitiesApiClient} from "@/api/entities.api.client";

export default {
  name: 'UserImagesDetails',
  inheritAttrs: false,
  components: {
    SpinnerComponent,
    ErrorDialogComponent
  },
  props: {
    orderId: String,
    entityId: String
  },

  data() {
    return {
      dialog: false,
      imagePreviewUrl: null,
      sourceFiles: {
        sources: [],
        headers: [
          {text: "Id", value: 'fileId'},
          {text: "Imię", value: 'name'},
          {text: "Akcje", sortable: false, value: 'actions'}
        ],
        parsedFiles: []
      },
      userImage: {
        name: '',
        image: undefined
      },

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  created() {
    this.getSources();
  },

  methods: {
    getSources() {
      this.loading = true;
      entitiesApiClient.getUserImages(this.orderId, this.entityId, (response) => {
        this.sourceFiles.parsedFiles = response.data.map(file => {
          return {
            fileId: file.id,
            name: file.name,
            link: ORDERS_URL + `/${this.orderId}/entities/${this.entityId}/user-image/${file.id}`
          }
        });
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => this.loading = false)
    },

    uploadUserImageFile() {
      if (this.userImage.name === undefined || this.userImage.name.length === 0) {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = "Proszę podać imię";
        throw new Error("Proszę podać imię")
      }

      if (this.userImage.image === undefined) {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = "Brak pliku";
        throw new Error("Brak pliku")
      }
      const formData = new FormData();

      formData.append("name", this.userImage.name);
      formData.append("image", this.userImage.image);
      this.loading = true;
      entitiesApiClient.postUserImage(this.orderId, this.entityId, formData, () => {
        this.getSources();
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => this.loading = false);
    },

    downloadUserImageFile(link, filename) {
      axios.get(link, {
        responseType: 'blob',
        headers: {
          'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
        }
      }).then(response => {
        if (filename !== undefined) {
          saveAs(response.data, filename);
        } else {
          saveAs(response.data);
        }
      }).catch(err => {
        console.error(err);
      });
    },

    previewUserImageFile(link) {
      axios.get(link, {
        responseType: 'blob',
        headers: {
          'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
        }
      }).then(response => {
        this.imagePreviewUrl = URL.createObjectURL(response.data)
      }).catch(err => {
        console.error(err);
      });
    },

    deleteUserImageFile(fileId) {
      this.loading = true;
      entitiesApiClient.deleteUserImage(this.orderId, this.entityId, fileId, () => {
        this.getSources();
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => this.loading = false);
    },

    updateUserImageName(id, name) {
      const data = {
        "id": id,
        "name": name
      }
      this.loading = true;
      entitiesApiClient.updateUserImageName(this.orderId, this.entityId, data, () => {
        this.getSources();
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => this.loading = false);
    }
  },

};
</script>

<style scoped>
.no-uppercase {
  text-transform: unset !important;
}
</style>
