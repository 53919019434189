/* eslint-disable */
<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-snackbar v-model="newsletterSent" timeout="3000">Wysłano</v-snackbar>
    <v-dialog
        v-model="dialog.visible"
    >
      <v-container>
        <v-row>
          <v-col>
            <v-btn
                @click="sendNewsletter"
            >
              Wyślij kampanie
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
                v-model="selectedAddresses"
                class="elevation-1"
                :headers="headers"
                :items="mailingList"
                :search="search"
                item-key="email"
                show-select
            >
              <template v-slot:top>
                <v-text-field
                    v-model="search"
                    label="Szukaj"
                    class="mx-4"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
                color="red"
                @click="closeDialog"
            >
              Zamknij
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>

import {computed} from "vue";
import {mailsApiClient} from "@/api/mails.api.client";
import ErrorDialogComponent from "@/components/ErrorDialogComponent.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
/* eslint-disable */
export default {
  name: 'NewsletterSendDialog',
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    dialog() {
      return this.modelValue ? this.modelValue : false
    }
  },

  created() {
    this.getMailingList();
  },

  emits: ['update:modelValue'], // The component emits an event
  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  components: {SpinnerComponent, ErrorDialogComponent},

  data() {
    return {
      search: '',
      mailingList: [],

      selectedAddresses: [],
      headers: [
        {text: "Adres email", value: 'email'},
        {text: "Dodano", value: 'addedAt'}
      ],

      newsletterSent: false,

      // MISC
      loading: false,
      testMailTriggered: {
        templateId: null,
        visible: false
      },
      errorDialog: {
        errorMessage: "",
        visible: false
      },
    }
  },

  methods: {
    getMailingList() {
      this.loading = true;
      mailsApiClient.getAddresses((response) => {
            this.mailingList = response.data.map(email => {
              return {
                email: email.address,
                addedAt: email.addedAt
              }
            })
          }, (e) => {
            this.errorDialog.visible = true;
            this.errorDialog.errorMessage = "Błąd podczas pobierania list mailingowych!";
            console.error(e);
          }, () => this.loading = false
      )
    },

    closeDialog() {
      this.dialog.visible = false;
    },

    sendNewsletter() {
      const addresses = this.selectedAddresses.map(address => address.email);
      if (addresses.length > 0) {
        mailsApiClient.sendNewsletter(this.dialog.templateId, addresses, () => {
          this.closeDialog();
          this.newsletterSent = true;
        });
      }
    }
  }
}
</script>
