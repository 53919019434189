'use strict'

module.exports.BASE_URL = process.env.VUE_APP_BOOKHERO_API_PATH;

module.exports = {
    PRODUCTS_URL: module.exports.BASE_URL + '/v1/products',
    FONTS_URL: module.exports.BASE_URL + '/v1/fonts',
    METADATA_URL: module.exports.BASE_URL + '/v1/metadata',
    ORDERS_URL: module.exports.BASE_URL + '/v1/orders',
    ENTITIES_URL: module.exports.BASE_URL + '/v1/orders/entities',
    INVOICES_URL: module.exports.BASE_URL + '/v1/invoices',
    VOUCHERS_URL: module.exports.BASE_URL + '/v1/vouchers',
    SHIPMENT_URL: module.exports.BASE_URL + '/v1/shipments',
    SHIPMENT_METHODS_URL: module.exports.BASE_URL + '/v1/shipments/methods',
    MAIL_URL: module.exports.BASE_URL + '/v1/mails',
    MAIL_TEMPLATES_URL: module.exports.BASE_URL + '/v1/mails/templates',
    NEWSLETTER_URL: module.exports.BASE_URL + '/v1/mails/newsletter',
    MAILING_LISTS_URL: module.exports.BASE_URL + '/v1/mails/lists',
    PROPERTIES_URL: module.exports.BASE_URL + '/v1/properties',
}
