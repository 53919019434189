<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
            v-model="local.name"
            label="Nazwa"
            required
        ></v-text-field>
      </v-col>
      <v-col v-if="!editMode">
        <v-text-field
            v-model="local.prefix"
            label="Prefix"
            required
        ></v-text-field>
      </v-col>
      <v-col v-if="!editMode">
        <v-text-field
            v-model="local.postfix"
            label="Postfix"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-checkbox
            label="Aktywny"
            color="green"
            hide-details
            v-model="local.active"
        ></v-checkbox>
      </v-col>
      <v-col cols="3" v-if="!editMode">
        <v-text-field
            v-model="local.length"
            label="Długość kodu [6-10]"
            required
        ></v-text-field>
      </v-col>
      <v-col cols="3" v-if="!editMode">
        <v-text-field
            v-model="local.amountOfCodes"
            label="Ilość kodów do wygenerowania"
            required
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
            label="Typ"
            color="green"
            :items="voucherTypes"
            item-text="name"
            item-value="value"
            v-model="local.type"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'VoucherBaseAddDetailsComponent',
  props: {
    modelValue: Object,
    editMode: Boolean
  },

  computed: {
    local() {
      return this.modelValue ? this.modelValue : {}
    }
  },

  components: {},

  data() {
    return {
      voucherTypes: [
        {
          name: "Procentowy",
          value: 'PERCENTAGE'
        },
        {
          name: "Wartościowy",
          value: 'VALUE'
        },
        {
          name: "Barter",
          value: 'BARTER'
        },
        {
          name: "Darmowy express",
          value: 'FREE_EXPRESS'
        },
        {
          name: "Darmowe dedykacje",
          value: 'FREE_DEDICATION'
        },
        {
          name: "Darmowa dostawa",
          value: 'FREE_SHIPPING'
        },
        {
          name: "Progresywny (ilośc książek)",
          value: 'PROGRESSIVE_BOOK_COUNT'
        }
      ]
    }
  },
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
