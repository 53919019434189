import {default as axios} from "axios";
import {addDays} from "@/main";
import Vue from "vue";

export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";

export class AuthUtils {
    login(loginData, successCallback, errorResponseHandler) {
        axios.post(process.env.VUE_APP_AUTH_URL, loginData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.VUE_APP_API_KEY
                }
            }
        ).then(response => {
            this.storeTokens(response.data);
            if (this.verifyApplicationRegistration(response.data.user)) {
                successCallback(response.data.user);
            }
        }).catch(err => {
            errorResponseHandler(err);
        });
    }

    refreshToken(refreshToken, successCallback, errorResponseHandler) {
        axios.post(process.env.VUE_APP_REFRESH_URL, {
                refreshToken: refreshToken
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'http://localhost:8080'
                }
            }
        ).then(response => {
            this.storeTokens(response.data);
            successCallback();
        }).catch(err => {
            errorResponseHandler(err);
        });
    }

    storeTokens(data) {
        if (data.token !== undefined && data.token !== null) {
            if (data.tokenExpirationInstant === undefined || data.tokenExpirationInstant === null) {
                Vue.$cookies.set(ACCESS_TOKEN, data.token, addDays(new Date(), 1))
            } else {
                Vue.$cookies.set(ACCESS_TOKEN, data.token, new Date(data.tokenExpirationInstant))
            }
        } else {
            throw new Error('Cannot store tokens.');
        }
        if (data.refreshToken !== undefined && data.refreshToken !== null) {
            Vue.$cookies.set(REFRESH_TOKEN, data.refreshToken, addDays(new Date(), 29))
        }
    }

    verifyApplicationRegistration(user) {
        if (user !== undefined && user !== null) {
            if (user.registrations === undefined || user.registrations === null || user.registrations.length === 0) {
                throw new Error('Unauthorized. Not registered to the application.');
            }
            const found = user.registrations.filter(registration => registration.applicationId === process.env.VUE_APP_APPLICATION_ID);

            if (found.length === 0 || !user.active || !user.verified) {
                throw new Error('Unauthorized. User either not active or not verified.');
            }
            return true;
        } else {
            return false;
        }
    }

    validateToken(token, successCallback, errorResponseHandler) {
        axios.get(process.env.VUE_APP_VALIDATE_TOKEN_URL,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': 'http://localhost:8080'
                }
            }
        ).then(response => {
            successCallback(response.data);
        }).catch(err => {
            errorResponseHandler(err);
        });
    }

    requireRoles(roles) {
        if (process.env.VUE_APP_AUTH_ENABLED === 'false') {
            return true;
        }
        if (roles.length === 0) {
            return true;
        }
        const user = JSON.parse(localStorage.getItem("user"));
        if (user === undefined || user === null) {
            return false;
        }
        const found = user.registrations.filter(registration => registration.applicationId === process.env.VUE_APP_APPLICATION_ID);
        if (found.length === 0 || !user.active || !user.verified) {
            throw new Error('Unauthorized. User either not active or not verified.');
        }

        return found[0].roles.some(role => roles.includes(role));
    }
}

export const authUtils = new AuthUtils();
