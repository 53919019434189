import Vue from 'vue'
import VueRouter from 'vue-router'
import VouchersView from "@/views/vouchers/VouchersView";
import DesignerView from "@/views/products/DesignerView";
import PagesView from "@/views/products/PagesView";
import ProductView from "@/views/products/UpdateProductView";
import AddNewProductView from "@/views/products/AddNewProductView";
import OrderDetailsView from "@/views/orders/OrderDetailsView";
import LoginView from "@/views/login/LoginView";
import ProfileView from "@/views/profile/ProfileView";
import {authUtils} from "@/api/auth.utils";
import DraftProductsView from "@/views/products/by-status/DraftProductsView";
import PublishedProductsView from "@/views/products/by-status/PublishedProductsView";
import AllProductsView from "@/views/products/by-status/AllProductsView";
import OrdersNewView from "@/views/orders/by-status/OrdersNewView";
import OrdersCanceledView from "@/views/orders/by-status/OrdersCanceledView";
import OrdersFinishedView from "@/views/orders/by-status/OrdersFinishedView";
import OrdersPrintableView from "@/views/orders/by-status/OrdersPrintableView";
import OrdersApprovalView from "@/views/orders/by-status/OrdersApprovalView";
import OrdersPaidView from "@/views/orders/by-status/OrdersPaidView";
import OrdersAllView from "@/views/orders/by-status/OrdersAllView";
import ShipmentMethodsView from "@/views/shipment/ShipmentMethodsView";
import NewShipmentMethodsView from "@/views/shipment/NewShipmentMethodsView";
import UpdateShipmentMethodsView from "@/views/shipment/UpdateShipmentMethodsView";
import NewMailDesignerView from "@/views/mails/NewMailDesignerView";
import MailTemplatesView from "@/views/mails/MailTemplatesView";
import EditMailDesignerView from "@/views/mails/EditMailDesignerView";
import PropertiesView from "@/views/properties/PropertiesView";
import AddPropertyView from "@/views/properties/AddPropertyView";
import EditPropertyView from "@/views/properties/EditPropertyView";
import BooksAll from "@/views/books/BooksAll";
import BooksAssignedToMe from "@/views/books/BooksAssignedToMe";
import NewsletterView from "@/views/mails/newletter/NewsletterView.vue";
import EditNewsletterView from "@/views/mails/newletter/EditNewsletterView.vue";
import NewNewsletterView from "@/views/mails/newletter/NewNewsletterView.vue";
import MailingListView from "@/views/mails/MailingListView.vue";
import AddNewVoucherView from "@/views/vouchers/AddNewVoucherView.vue";
import EditVoucherView from "@/views/vouchers/EditVoucherView.vue";
import VoucherCodesView from "@/views/vouchers/VoucherCodesView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'main',
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: []
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: ProfileView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: []
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            requiresAuth: false,
            requiredRoles: []
        }
    },
    {
        path: '/orders/all',
        name: 'orders',
        component: OrdersAllView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/orders/new',
        name: 'orders',
        component: OrdersNewView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/orders/paid',
        name: 'orders',
        component: OrdersPaidView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/orders/approval',
        name: 'orders',
        component: OrdersApprovalView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/orders/printable',
        name: 'orders',
        component: OrdersPrintableView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/orders/finished',
        name: 'orders',
        component: OrdersFinishedView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/orders/canceled',
        name: 'orders',
        component: OrdersCanceledView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/orders/:orderId',
        name: 'order-details',
        component: OrderDetailsView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal', 'contractor']
        }
    },
    {
        path: '/books/all',
        name: 'books',
        component: BooksAll,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal', 'contractor']
        }
    },
    {
        path: '/books/my',
        name: 'booksMy',
        component: BooksAssignedToMe,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal', 'contractor']
        }
    },
    {
        path: '/products/all',
        name: 'products',
        component: AllProductsView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/products/published',
        name: 'published-products',
        component: PublishedProductsView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/products/draft',
        name: 'drafted-products',
        component: DraftProductsView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/products/new',
        name: 'new-product',
        component: AddNewProductView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/products/:productId',
        name: 'product',
        component: ProductView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/products/:productId/pages',
        name: 'pages',
        component: PagesView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/products/:productId/pages/:page',
        name: 'designer',
        component: DesignerView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/vouchers/group/:groupType',
        name: 'vouchers-group',
        component: VouchersView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/vouchers/group/:groupType/:id/codes',
        name: 'vouchers-codes',
        component: VoucherCodesView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/vouchers/group/:groupType/:id',
        name: 'edit-voucher',
        component: EditVoucherView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/vouchers/group/:groupType/new',
        name: 'new-voucher',
        component: AddNewVoucherView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/shipment-methods/all',
        name: 'shipment-methods-all',
        component: ShipmentMethodsView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/shipment-methods/new',
        name: 'new-shipment-method',
        component: NewShipmentMethodsView,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/shipment-methods/:methodId/edit',
        name: 'edit-shipment-method',
        component: UpdateShipmentMethodsView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/mails-all',
        name: 'mails-all',
        component: MailTemplatesView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/mails-new',
        name: 'mails-new',
        component: NewMailDesignerView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/mails/:id/edit',
        name: 'mails-edit',
        component: EditMailDesignerView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/newsletter-all',
        name: 'newsletter-all',
        component: NewsletterView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/newsletter-new',
        name: 'newsletter-new',
        component: NewNewsletterView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/newsletter/:id/edit',
        name: 'newsletter-edit',
        component: EditNewsletterView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/mailing-all',
        name: 'mailing-all',
        component: MailingListView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        }
    },
    {
        path: '/properties',
        name: 'properties',
        component: PropertiesView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        },
    },
    {
        path: '/properties-new',
        name: 'properties-new',
        component: AddPropertyView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        },
    },
    {
        path: '/properties/:id',
        name: 'properties-edit',
        component: EditPropertyView,
        props: true,
        meta: {
            requiresAuth: true,
            requiredRoles: ['admin', 'internal']
        },
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            requiresAuth: true,
            requiredRoles: []
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (process.env.VUE_APP_AUTH_ENABLED === 'false') {
        return next()
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!Vue.$cookies.isKey("access_token")) {
            if (Vue.$cookies.isKey("refresh_token")) {
                authUtils.refreshToken(Vue.$cookies.get("refresh_token"), () => {
                    if (to.matched.some(record => authUtils.requireRoles(record.meta.requiredRoles))) {
                        return next()
                    } else {
                        throw new Error('Unauthorized.')
                    }
                }, () => {
                    return next({name: 'login'})
                })
            } else {
                return next({name: 'login'})
            }
        } else {
            if (to.matched.some(record => authUtils.requireRoles(record.meta.requiredRoles))) {
                return next()
            } else {
                throw new Error('Unauthorized.')
            }
        }
    } else {
        return next()
    }
})

export default router
