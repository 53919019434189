export const resolveOrderStatus = (statusString) => {
    switch (statusString) {
        case "all":
            return null;
        case "new":
            return 53;
        case "paid":
            return 103;
        case "approval":
            return 153;
        case "printable":
            return 203;
        case "finished":
            return 303;
        case "canceled":
            return 403;
        default:
            return null;
    }
}