<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-container>
      <v-row>
        <v-col>
          <v-text-field
              placeholder="Klucz"
              v-model="key"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
              placeholder="Opis"
              v-model="description"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
              placeholder="Wartość"
              v-model="value"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <router-link to="/properties">
            <v-btn
                block
                color="primary"
                elevation="2"
                large
            >
              Wróć
            </v-btn>
          </router-link>
        </v-col>
        <v-col>
          <v-btn
              block
              color="primary"
              elevation="2"
              large
              @click="saveProperty"
          >
            Zapisz
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {propertiesApiClient} from "@/api/properties.api.client";

export default {
  name: "AddPropertyView",
  inheritAttrs: false,
  components: {SpinnerComponent, ErrorDialogComponent},

  props: {},

  created() {
  },

  data() {
    return {
      key: '',
      description: '',
      value: '',

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  methods: {
    saveProperty() {
      const data = {
        key: this.key,
        description: this.description,
        value: this.value
      }
      propertiesApiClient.addProperties(data, () => {
        this.$router.push('/properties')
      }, (error) => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
