<template>
  <v-container>
    <v-row>
      <v-col
          cols="12"
          md="4"
      >

        <v-text-field
            v-model="local.prefixTitle"
            label="Tytuł początkowy"
            required
        ></v-text-field>
      </v-col>

      <v-col
      >
        <v-text-field
            v-model="local.title"
            :counter="10"
            label="Tytuł"
            required
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
          cols="12"
          md="6"
      >
        <v-text-field
            v-model="local.price"
            label="Cena"
            required
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          md="6"
      >
        <v-text-field
            v-model="local.salePrice"
            label="Cena promocyjna"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
          cols="12"
          md="6"
      >
        <v-textarea
            solo
            v-model="local.description"
            label="Opis"
        ></v-textarea>
      </v-col>

      <v-col
          cols="12"
          md="6"
      >
        <v-textarea
            solo
            v-model="local.details"
            label="Opis szczegółowy"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col
          cols="12"
          md="2"
      >
        <v-text-field
            v-model="local.position"
            label="Pozycja na liście"
            required
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          md="3"
      >
        <v-text-field
            v-model="local.locale"
            label="Lokalizacja"
            required
        ></v-text-field>
      </v-col>

      <v-col
          cols="12"
          md="3"
      >
        <v-select
            :items="allowedTypes"
            label="Typ produktu"
            v-model="local.type"
        ></v-select>
      </v-col>
      <v-col
          cols="12"
          md="4"
      >
        <v-select
            :items="allowedCategories"
            v-model="local.categories"
            label="Kategorie"
            multiple
            attach
            chips
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          cols="12"
          md="2"
      >
        <v-text-field
            v-model="local.kidCount"
            label="Ile dzieci"
        ></v-text-field>
      </v-col>

      <v-col
          cols="12"
          md="3"
      >
        <v-checkbox
            v-model="local.promoted"
            label="Promowanie produktu"
        ></v-checkbox>
      </v-col>

      <v-col
          cols="12"
          md="3"
      >
        <v-text-field
            v-model="local.suggestedAge"
            label="Sugerowany wiek"
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          md="4"
      >
        <v-text-field
            v-model="local.kind"
            label="Rodzaj książeczki"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {computed} from "vue";

export default {
  name: 'ProductDetails',
  inheritAttrs: false,
  components: {},
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    local() {
      return this.modelValue ? this.modelValue : {}
    }
  },

  emits: ['update:modelValue'], // The component emits an event
  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  data() {
    return {
      allowedTypes: ['BH', 'JWB'],
      allowedCategories: ['BOY', 'GIRL', 'BOTH', 'SPECIAL'],
    }
  },

  created() {
  },

  methods: {
    isTypeActive(type) {
      return this.local.type === type;
    },

    applyType(type) {
      if (this.modelValue.type === null || this.modelValue.type === '') {
        this.local.type = type;
      } else if (this.modelValue.type === type) {
        this.local.type = null;
      }
    },

    applyCategory(category) {
      if (this.local.categories.includes(category)) {
        const elem = this.local.categories.findIndex(c => c === category);
        delete this.local.categories[elem];
      } else {
        this.local.categories.push(category);
      }
    }
  }
};
</script>
