<template>
  <v-dialog
      v-model="testMailDialog.visible"
  >
    <v-card>
      <v-card-title>Error</v-card-title>
      <v-card-text>
        <v-combobox
            v-model="targetEmails"
            chips
            clearable
            label="Do kogo wysłać"
            multiple
            prepend-icon="mdi-filter-variant"
            solo
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="remove(item)"
            >
              <strong>{{ item }}</strong>&nbsp;
            </v-chip>
          </template>
        </v-combobox>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="blue"
            text
            @click="sendTestEmail()"
        >
          Wyślij testowy mail
        </v-btn>
        <v-btn
            color="red"
            text
            @click="closeDialog()"
        >
          Zamknij
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {computed} from "vue";
import {mailsApiClient} from "@/api/mails.api.client";

export default {
  name: 'TestMailDialog',
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    testMailDialog() {
      return this.modelValue ? this.modelValue : false
    }
  },

  emits: ['update:modelValue'], // The component emits an event
  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  components: {},

  data() {
    return {
      targetEmails: []
    }
  },

  methods: {
    closeDialog() {
      this.testMailDialog.visible = false;
    },

    sendTestEmail() {
      const data = {
        addresses: this.targetEmails,
        templateId: this.testMailDialog.templateId
      }
      mailsApiClient.sendTestEmail(data, () => {
        this.closeDialog();
      });
    },

    remove(item) {
      this.targetEmails.splice(this.shipmentZones.indexOf(item), 1)
    },
  }
}
</script>
