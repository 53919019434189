<template>
  <orders-base-view status="paid"></orders-base-view>
</template>

<script>

import OrdersBaseView from "@/components/orders/OrdersBaseView";
export default {
  name: 'OrdersPaidView',

  components: {OrdersBaseView},
};
</script>
