<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-container>
      <v-row>
        <v-col>
          <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="parsedBooks"
              :search="search"
          >
            <template v-slot:top>
              <v-text-field
                  v-model="search"
                  label="Szukaj"
                  class="mx-4"
              ></v-text-field>
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="dates"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model ="dates"
                              label="Wybierz zakres dat"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="dates"
                            no-title
                            scrollable
                            range
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                              text
                              color="primary"
                              @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              @click="savesDates(dates)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                            v-model="bookType.values"
                            :items="bookType.items"
                            attach
                            chips
                            label="Typ"
                            multiple
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-select
                            v-model="statuses.values"
                            :items="statuses.items"
                            attach
                            chips
                            label="Status"
                            multiple
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-btn
                        @click="applyFilter()"
                    >
                      Filtruj
                    </v-btn>
                  </v-container>
                </v-card-text>
              </v-card>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >
                    Akcje
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <router-link :to="{ name: 'order-details', params: { orderId: item.orderId } }">
                        <v-btn>Podgląd</v-btn>
                      </router-link>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SpinnerComponent from "@/components/SpinnerComponent";
import {addDays, subtractDays} from "@/main";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {entitiesApiClient} from "@/api/entities.api.client";

export default {
  name: 'BooksAllView',
  inheritAttrs: false,
  components: {SpinnerComponent, ErrorDialogComponent},

  props: {
    status: String
  },

  data() {
    return {
      menu: false,
      dates: [this.lastMonth(), this.getTomorrow()],
      search: '',
      parsedBooks: [],
      originalBooks: [],
      headers: [
        {text: "Id zamówienia", value: 'orderVisibleId'},
        {text: "Id książeczki", value: 'orderEntityVisibleId'},
        {text: "Typ", value: 'type'},
        {text: "Nazwa", value: 'title'},
        {text: "Data złożenia", value: 'orderedAt'},
        {text: "Status", value: 'status'},
        {text: "Akcje", sortable: false, value: 'actions'}
      ],
      bookType: {
        items: ['BH', 'JWB'],
        values: []
      },
      statuses: {
        items: [
          'NEW',
          'GENERATING',
          'GENERATED',
          'VERIFIED',
          'PRINTING',
          'DONE',
          'FAILED',
          'ASSIGNED',
          'ASSEMBLED'
        ],
        values: []
      },

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  created() {
    this.loading = true;
    this.getBooksWithLimit(20);
  },

  methods: {
    getBooksWithLimit(limit) {
      entitiesApiClient.getBooksWithLimit(limit, response => {
        this.originalBooks = response.data;
        this.parsedBooks = [];

        response.data.forEach(entity => {
          this.parsedBooks.push({
            'orderId': entity.orderId,
            'orderVisibleId': entity.orderVisibleId,
            'orderEntityId': entity.orderEntityId,
            'orderEntityVisibleId': entity.orderEntityVisibleId,
            'type': entity.type,
            'title': entity.productTitle,
            'status': entity.status,
            'orderedAt': entity.orderedAt
          })
        })
        this.applyFilter();
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => this.loading = false)
    },

    getBooksWithDates(from, to) {
      entitiesApiClient.getBooksWithDates(from, to, response => {
        this.originalBooks = response.data;
        this.parsedBooks = [];

        response.data.forEach(entity => {
          this.parsedBooks.push({
            'orderId': entity.orderId,
            'orderVisibleId': entity.orderVisibleId,
            'orderEntityId': entity.orderEntityId,
            'orderEntityVisibleId': entity.orderEntityVisibleId,
            'type': entity.type,
            'title': entity.productTitle,
            'status': entity.status,
            'orderedAt': entity.orderedAt
          })
        })
        this.applyFilter();
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => this.loading = false)
    },

    lastMonth() {
      return subtractDays(
          new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000),
          30
      ).toISOString().substr(0, 10);
    },

    getTomorrow() {
      return addDays(
          new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000),
          1
      ).toISOString().substr(0, 10);
    },

    savesDates(dates) {
      this.$refs.menu.save(dates);
      this.getBooksWithDates(dates[0], dates[1]);
    },

    applyFilter() {
      this.parsedBooks = [];
      this.originalBooks
          .filter(book => this.statuses.values.length === 0 ? true : this.statuses.values.includes(book.status))
          .filter(book => this.bookType.values.length === 0 ? true : this.bookType.values.includes(book.type))
          .forEach(book => {
            this.parsedBooks.push({
              'orderId': book.orderId,
              'orderVisibleId': book.orderVisibleId,
              'orderEntityId': book.orderEntityId,
              'orderEntityVisibleId': book.orderEntityVisibleId,
              'type': book.type,
              'title': book.productTitle,
              'status': book.status,
              'orderedAt': book.orderedAt
            })
          });
      if (this.parsedBooks.length > 0) {
        const earliestDate = this.parsedBooks.reduce((prev, curr) => prev.orderedAt < curr.orderedAt ? prev : curr)
        this.dates[0] = new Date(earliestDate.orderedAt).toISOString().split('T')[0];
        this.$refs.menu.save(this.dates);
      }
    }
  }
};
</script>
