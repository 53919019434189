<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-container>
      <v-row>
        <v-col>
          <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="templates"
              :search="search"
          >
            <template v-slot:top>
              <v-text-field
                  v-model="search"
                  label="Szukaj"
                  class="mx-4"
              ></v-text-field>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >
                    Akcje
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                          @click="editNewsletterTemplate(item)"
                      >Edytuj
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                          @click="deleteNewsletterTemplate(item.id)"
                      >Usuń
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>


import SpinnerComponent from "@/components/SpinnerComponent.vue";
import ErrorDialogComponent from "@/components/ErrorDialogComponent.vue";
import {mailsApiClient} from "@/api/mails.api.client";

export default {
  name: 'NewsletterView',

  components: {SpinnerComponent, ErrorDialogComponent},

  data() {
    return {
      search: '',
      templates: [],
      headers: [
        {text: "Id", value: 'id'},
        {text: "Nazwa", value: 'name'},
        {text: "Tytuł", value: 'subject'},
        {text: "Dodano", value: 'addedAt'},
        {text: "Akcje", sortable: false, value: 'actions'}
      ],

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  created() {
    this.getNewsletters();
  },

  methods: {
    getNewsletters() {
      this.loading = true;
      mailsApiClient.getNewsletters(response => {
        this.templates = response.data.map(template => {
          return {
            id: template.id,
            name: template.name,
            subject: template.subject,
            design: template.design,
            addedAt: template.addedAt,
            html: template.html
          }
        });
      }, () => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = "Błąd podczas pobierania wzorów!";
      }, () => this.loading = false)
    },

    editNewsletterTemplate(item) {
      this.$router.push({name: 'newsletter-edit', params: {id: item.id, templateProp: item}})
    },

    deleteNewsletterTemplate(id) {
      mailsApiClient.removeTemplate(id, () => {
        this.getNewsletters();
      }, (e) => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = "Błąd podczas usuwania wzoru!";
        console.error(e);
      }, () => {})
    }
  }
};
</script>