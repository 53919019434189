<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-container>
      <router-link :to="{ name: 'properties-new' }">
        <v-btn block
               color="primary"
               elevation="2"
               large
        >
          Dodaj nową zmienną
        </v-btn>
      </router-link>
      <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="properties"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                Akcje
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <router-link :to="{ name: 'properties-edit', params: { id: item.id } }">
                    <v-btn>Edytuj</v-btn>
                  </router-link>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                      @click="deleteProperty(item.id)"
                  >Usuń
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>

import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {propertiesApiClient} from "@/api/properties.api.client";

export default {
  name: "PropertiesView",
  inheritAttrs: false,
  components: {SpinnerComponent, ErrorDialogComponent},

  props: {},

  created() {
    this.getProperties();
  },

  data() {
    return {
      headers: [
        {text: "Id", value: 'id'},
        {text: "Nazwa zmiennej", value: 'key'},
        {text: "Wartość zmiennej", value: 'value'},
        {text: "Akcje", sortable: false, value: 'actions'}
      ],
      properties: [],

      // MISC
      loading: true,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  methods: {
    getProperties() {
      propertiesApiClient.getProperties((response) => {
        this.properties = response.data;
      }, (error) => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => {
        this.loading = false;
      })
    },

    deleteProperty(id) {
      this.loading = true;
      propertiesApiClient.deleteProperties(id, () => {
        this.getProperties();
      }, (error) => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
