<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-container>
      <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="parsedOrders"
          :search="search"
          :sort-by="['orderedAt']"
          :sort-desc="[true]"
      >
        <template v-slot:top>
          <v-text-field
              v-model="search"
              label="Szukaj"
              class="mx-4"
          ></v-text-field>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-btn-toggle>
                  <v-btn
                      @click="downloadSpedLabelsAsZip()"
                  >
                    Pobierz listy przewozowe jako ZIP
                  </v-btn>
                  <v-btn>
                    Drukuj listy przewozowe
                  </v-btn>
                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="dates"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="dates"
                          label="Wybierz zakres dat"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dates"
                        no-title
                        scrollable
                        range
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="savesDates(dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-btn-toggle>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                Akcje
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <router-link :to="{ name: 'order-details', params: { orderId: item.id } }">
                    <v-btn>Detale</v-btn>
                  </router-link>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="item.status === 'PRINTING'">
                <v-list-item-title>
                  <v-btn
                      @click="markAsFinished(item)"
                  >Oznacz jako ukończone
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="spedLabelVisibilityStatus.includes(item.status)">
                <v-list-item-title>
                  <v-btn
                      @click="generateSpedLabel(item.id, item.orderVisibleId)"
                  >
                    Pobierz list przewozowy
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                      @click="deleteOrder(item.id)"
                  >Usuń
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>

import {saveAs} from "file-saver";
import SpinnerComponent from "@/components/SpinnerComponent";
import {addDays, subtractDays} from "@/main";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {shipmentApiClient} from "@/api/shipment.api.client";
import {ordersApiClient} from "@/api/orders.api.client";
import {resolveOrderStatus} from "@/utils/order-status";


export default {
  name: 'OrdersBaseView',

  components: {SpinnerComponent, ErrorDialogComponent},

  props: {
    status: String
  },

  data() {
    return {
      search: '',
      menu: false,
      dates: [this.lastMonth(), this.getTomorrow()],
      orders: [],
      parsedOrders: [],
      headers: [
        {text: "Typ", value: 'orderType'},
        {text: "Id", value: 'orderVisibleId'},
        {text: "Klient", value: 'client'},
        {text: "Data złożenia", value: 'orderedAt'},
        {text: "Wartość", value: 'orderValue'},
        {text: "Status", value: 'status'},
        {text: "Akcje", sortable: false, value: 'actions'}
      ],
      spedLabelVisibilityStatus : [
        'TO_BE_PRINTED',
        'PRINTING',
        'SENDING_INVOICE',
        'FINISHED'
      ],

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false
      }
    }
  },

  created() {
    this.handleStatus();
  },

  methods: {
    lastMonth() {
      return subtractDays(
          new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000),
          30
      ).toISOString().substr(0, 10);
    },

    getTomorrow() {
      return addDays(
          new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000),
          1
      ).toISOString().substr(0, 10);
    },

    savesDates(dates) {
      this.$refs.menu.save(dates);
      this.getOrdersWithDates(resolveOrderStatus(this.status), dates[0], dates[1]);
    },

    downloadSpedLabelsAsZip() {
      this.loading = true;
      const data = [];
      this.parsedOrders.forEach(order => {
        data.push(order.id)
      })
      shipmentApiClient.downloadSpedLabelsAsZip(data, response => {
        saveAs(response.data, 'listy_przewozowe.zip');
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => this.loading = false)
    },

    handleStatus() {
      this.getOrdersWithLimit(resolveOrderStatus(this.status), 20);
    },

    getOrders(status) {
      this.loading = true;
      ordersApiClient.getOrdersByStatus(status, (response) => {
        this.orders = response.data;
        this.orders.forEach(order => {
          this.parsedOrders.push(Object({
            id: order.id,
            orderVisibleId: order.orderVisibleId,
            orderType: order.orderType,
            status: order.status,
            orderedAt: order.metadata.orderedAt,
            orderValue: order.orderValue,
            client: order.orderShipment.name + ' ' + order.orderShipment.surname
          }));
        })
      }, (error) => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => {
        this.loading = false;
      });
    },

    getOrdersWithLimit(status, limit) {
      this.loading = true;
      ordersApiClient.getOrdersByStatusWithLimit(status, limit, (response) => {
        this.orders = response.data;
        this.parsedOrders = [];

        this.orders.forEach(order => {
          this.parsedOrders.push(Object({
            id: order.id,
            orderVisibleId: order.orderVisibleId,
            orderType: order.orderType,
            status: order.status,
            orderedAt: order.metadata.orderedAt,
            orderValue: order.orderValue,
            client: order.orderShipment.name + ' ' + order.orderShipment.surname
          }));
        })
      }, (error) => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => {
        this.loading = false;
      });
    },

    getOrdersWithDates(status, from, to) {
      this.loading = true;
      ordersApiClient.getOrdersByStatusWithDates(status, from, to, (response) => {
        this.orders = response.data;
        this.parsedOrders = [];

        this.orders.forEach(order => {
          this.parsedOrders.push(Object({
            id: order.id,
            orderVisibleId: order.orderVisibleId,
            orderType: order.orderType,
            status: order.status,
            orderedAt: order.metadata.orderedAt,
            orderValue: order.orderValue,
            client: order.orderShipment.name + ' ' + order.orderShipment.surname
          }));
        })
      }, (error) => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => {
        this.loading = false;
      });
    },

    generateSpedLabel(orderId, filename) {
      this.loading = true;
      shipmentApiClient.generateSpedLabel(orderId, (response) => {
        if (response.headers['content-type'] === 'text/plain') {
          saveAs(response.data, filename + '.csv');
        } else if (response.headers['content-type'] === 'application/pdf') {
          saveAs(response.data, filename + '.pdf');
        } else {
          this.errorDialog.errorMessage = 'Nieobsługiwany format pliku';
        }
      }, (error) => {
        this.errorDialog.visible = true;
        error.response.data.text().then(text => {
          const errorMessage = JSON.parse(text);
          this.errorDialog.errorMessage = errorMessage.detail;
        });
      }, () => {
        this.loading = false;
      })
    },

    deleteOrder(id) {
      ordersApiClient.deleteOrder(id, () => {
        this.getOrders();
      });
    },

    markAsFinished(item) {
      ordersApiClient.markAsFinished(item.id, () => {
        item.status = 'SENDING_INVOICE';
      }, (error) => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
      }, () => {
        this.loading = false;
      });
    }
  }
};
</script>
