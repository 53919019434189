<template>
  <div>
    <spinner-component :loaded="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-container>
      <v-row>
        <v-col cols="4">
          <v-text-field
              v-model="name"
              label="Nazwa metody dostawy"
          >

          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
              :items="couriers"
              label="Obsługiwane usługi kurierskie"
              v-model="courier"
          >

          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
              @click="addNewShipmentZone()"
          >
            Dodaj nową strefe
          </v-btn>
          <v-list>
            <v-list-item
                v-for="(zone, i) in shipmentZones"
                :key="i"
            >
              <v-text-field
                  label="Nazwa"
                  v-model="zone.name"
              >
              </v-text-field>

              <v-text-field
                  label="Koszt"
                  v-model="zone.cost"
              >
              </v-text-field>

              <v-combobox
                  v-model="zone.supportedCountries"
                  chips
                  clearable
                  label="Strefy dostawy"
                  multiple
                  prepend-icon="mdi-filter-variant"
                  solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click="select"
                      @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong>&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
              @click="addNewShipmentMethod()"
          >
            Dodaj
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import SpinnerComponent from "@/components/SpinnerComponent";
import {shipmentApiClient} from "@/api/shipment.api.client";

export default {
  name: 'NewShipmentMethodsView',
  inheritAttrs: false,
  components: {ErrorDialogComponent, SpinnerComponent},

  data() {
    return {
      name: '',
      shipmentZones: [],
      courier: '',
      couriers: [],

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        errorDetails: '',
        visible: false
      }
    }
  },

  created() {
    this.getCouriers()
  },

  methods: {
    getCouriers() {
      this.loading = true;
      shipmentApiClient.getCouriers((response) => {
        this.couriers = response.data;
      }, () => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = "Błąd ładowanie dostępnych usług kurierskich!";
      }, () => this.loading = false)
    },

    remove(item) {
      this.shipmentZones.splice(this.shipmentZones.indexOf(item), 1)
    },

    addNewShipmentZone() {
      this.shipmentZones.push({
        name: '',
        cost: '',
        supportedCountries: []
      });
    },

    addNewShipmentMethod() {
      const data = {
        name: this.name,
        couriers: this.courier,
        shipmentZones: this.shipmentZones
      };
      this.loading = true;
      shipmentApiClient.addShipmentMethod(data, () => {
        this.$router.push({name: 'shipment-methods-all'})
      }, () => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = "Błąd podczas dodawania nowej metody dostawy!";
      }, () => this.loading = false)
    }
  }
}
</script>
