<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
            label="Procenty"
            type="number"
            v-model="local.percentage"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            label="Maksymalna wartość [zł]"
            type="number"
            v-model="local.maximumValue"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
            label="Darmowa dostawa"
            v-model="local.freeShipping"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
            label="Darmowa dedykacja"
            v-model="local.freeDedications"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {computed} from "vue";

export default {
  name: 'PercentageVoucherType',
  props: {
    modelValue: Object,
  },

  computed: {
    local() {
      return this.modelValue ? this.modelValue : {}
    }
  },

  emits: ['update:modelValue'], // The component emits an event

  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  components: {},

  data() {
    return {}
  },
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
