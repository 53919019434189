import {default as axios} from "axios";
import {METADATA_URL} from "@/config/api.config";
import Vue from "vue";
import {ACCESS_TOKEN} from "@/api/auth.utils";

export class MetadataApiClient {
    updateBookPage(productId, page, jsonData, successCallback, errorCallback, finallyCallback) {
        axios.put(METADATA_URL + `/${productId}/${page}`, jsonData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => finallyCallback());
    }

    addNewBookPage(productId, jsonData, successCallback, errorCallback, finallyCallback) {
        return axios.post(METADATA_URL + `/${productId}`, jsonData, {
            headers: {
                'Content-Type': 'application/json', 'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            },
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => finallyCallback());
    }

    getProductMetadataPage(productId, page, successCallback, errorCallback, finallyCallback) {
        return axios.get(METADATA_URL + `/${productId}/${page}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => {
            finallyCallback();
        });
    }

    getPages(productId, successCallback, errorCallback, finallyCallback) {
        return axios.get(METADATA_URL + `/${productId}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(err => {
            errorCallback(err);
        }).finally(() => finallyCallback());
    }

    deletePage(productId, page, successCallback, errorCallback, finallyCallback) {
        axios.delete(METADATA_URL + `/${productId}/${page.page}`, {
            headers: {
                'Authorization': 'Bearer ' + Vue.$cookies.get(ACCESS_TOKEN)
            }
        }).then(response => {
            successCallback(response);
        }).catch(error => {
            errorCallback(error)
        }).finally(() => finallyCallback());
    }
}

export const metadataApiClient = new MetadataApiClient();