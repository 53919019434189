<template>
  <products-base-view status="published"></products-base-view>
</template>

<script>
import ProductsBaseView from "@/components/products/ProductsBaseView";
export default {
  name: 'PublishedProductsView',
  inheritAttrs: false,
  components: {ProductsBaseView},
};
</script>
