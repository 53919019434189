<template>
  <div>
    <spinner-component :loading="loading"></spinner-component>
    <error-dialog-component :model-value="this.errorDialog"></error-dialog-component>
    <v-card>
      <v-card-title>
        Dane do faktury
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row v-if="invoice.invoiceData.companyInvoice">
            <v-col>
              <v-text-field
                  :disabled="!edit"
                  label="Nazwa firmy"
                  v-model="invoice.invoiceData.companyName"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  :disabled="!edit"
                  label="NIP"
                  v-model="invoice.invoiceData.nip"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="!invoice.invoiceData.companyInvoice">
            <v-col>
              <v-text-field
                  :disabled="!edit"
                  label="Imię"
                  v-model="invoice.invoiceData.name"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  :disabled="!edit"
                  label="Nazwisko"
                  v-model="invoice.invoiceData.surname"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  :disabled="!edit"
                  label="Ulica"
                  v-model="invoice.invoiceData.street"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  :disabled="!edit"
                  label="Numer domu"
                  v-model="invoice.invoiceData.houseNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="2" v-if="invoice.invoiceData.aptNumber">
              <v-text-field
                  :disabled="!edit"
                  label="Numer lokalu"
                  v-model="invoice.invoiceData.aptNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field
                  :disabled="!edit"
                  label="Kod pocztowy"
                  v-model="invoice.invoiceData.zipCode"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  :disabled="!edit"
                  label="Miasto"
                  v-model="invoice.invoiceData.city"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Kraj"
                  v-model="invoice.invoiceData.country"
                  :disabled="!edit"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Adres email"
                  v-model="invoice.invoiceData.email"
                  :disabled="!edit"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  :disabled="!edit"
                  label="Numer telefonu"
                  v-model="invoice.invoiceData.phone"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn block
                     color="primary"
                     elevation="2"
                     large
                     :disabled="edit"
                     @click="generateInvoice()"
              >
                Pobierz fakturę
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="edit" @click="edit = true">Edytuj</v-btn>
        <v-btn :disabled="!edit" @click="save()">Zapisz</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {saveAs} from "file-saver";
import {computed} from "vue";
import SpinnerComponent from "@/components/SpinnerComponent";
import ErrorDialogComponent from "@/components/ErrorDialogComponent";
import {invoiceApiClient} from "@/api/invoice.api.client";
import {ordersApiClient} from "@/api/orders.api.client";

export default {
  name: 'OrderInvoiceDetails',
  inheritAttrs: false,
  components: {SpinnerComponent, ErrorDialogComponent},
  props: {
    orderId: String,
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    invoice() {
      return this.modelValue ? this.modelValue : {}
    }
  },

  emits: ['update:modelValue'], // The component emits an event
  setup(props, {emit}) {
    const theModel = computed({  // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {theModel};
  },

  data() {
    return {
      edit:false,

      // MISC
      loading: false,
      errorDialog: {
        errorMessage: "",
        visible: false,
        callback: undefined
      }
    }
  },

  created() {
  },

  methods: {
    generateInvoice() {
      this.loading = true;
      invoiceApiClient.getInvoice(this.orderId, (response) => {
        saveAs(response.data, `${this.orderId}_invoice.pdf`);
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
        console.error(error);
      }, () => this.loading = false)
    },

    save() {
      this.loading = true;
      ordersApiClient.updateInvoiceData(this.orderId, this.invoice.invoiceData, () => {
      }, error => {
        this.errorDialog.visible = true;
        this.errorDialog.errorMessage = error.response.data.detail;
        this.errorDialog.callback = () => this.$router.go(0);
      }, () => {
        this.loading = false;
        this.edit = false;
      })
    }
  }
};
</script>
