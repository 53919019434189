<template>
  <v-container>
    <v-row>
      <v-col>
        <v-data-table
            :headers="headers"
            :items="parsedMethods"
            :search="search"
            class="elevation-1"
        >
          <template v-slot:top>
            <v-text-field
                v-model="search"
                label="Szukaj"
                class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:[`item.zones`]="{ item }">
            <shipment-zones-component :zones="item.zones"></shipment-zones-component>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  Akcje
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                        @click="editShipmentMethod(item)"
                    >
                      Edytuj
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                        @click="removeShipmentMethod(item)"
                    >
                      Usuń
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ShipmentZonesComponent from "@/components/shipments/ShipmentZonesComponent";
import {shipmentApiClient} from "@/api/shipment.api.client";

export default {
  name: 'ShipmentMethodsView',
  inheritAttrs: false,
  components: {ShipmentZonesComponent},

  data() {
    return {
      shipmentMethods: [],
      search: '',
      parsedMethods: [],
      headers: [
        {text: "Name", value: 'name'},
        {text: "Dostawca", value: 'courier'},
        {text: "Strefy", value: 'zones'},
        {text: "Akcje", sortable: false, value: 'actions'}
      ]
    }
  },

  created() {
    this.getShipmentMethods()
  },

  methods: {
    getShipmentMethods() {
      shipmentApiClient.getShipmentMethods((response) => {
        this.parsedMethods = response.data.map(method => {
          return {
            id: method.id,
            name: method.name,
            courier: method.couriers,
            zones: method.shipmentZones
          }
        });
      }, (error) => {
        console.error(error);
      })
    },

    editShipmentMethod(method) {
      this.$router.push({name: 'edit-shipment-method', params: {methodId: method.id}})
    },

    removeShipmentMethod(method) {
      shipmentApiClient.deleteShipmentMethod(method.id, () => {
        this.getShipmentMethods();
      }, (error) => {
        console.error(error);
      })
    }
  }
}
</script>
