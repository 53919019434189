<template>
  <orders-base-view status="finished"></orders-base-view>
</template>

<script>

import OrdersBaseView from "@/components/orders/OrdersBaseView";
export default {
  name: 'OrdersFinishedView',

  components: {OrdersBaseView},
};
</script>
